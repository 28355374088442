import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        careersPage(locale: $locale) {
          headSeoBreadcrumbTitle
          headSeo {
            title
            description
          }
          careersCardsArea {
            ... on TheNuHeaderPressCareersAboutUsPageRecord {
              id
              componentName
              theNuHeaderTitle
              theNuHeaderSuperTitle
            }
            ... on CareerspageSectionPresentationRecord {
              id
              componentName
              title
              description
              ctaLink
              ctaText
            }
            ... on CareerspageSectionpillarRecord {
              id
              componentName
              title
              description
            }

            ... on CareerspageSectionNubankerRecord {
              id
              componentName
              title
              description
              nubankersList {
                id
                title
                url
              }
            }
            ... on  CareerspageSectionPressRecord {
                id
                componentName
                newsForRole {
                  id
                  title
                  ctaLink
                  ctaText
                }
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    careersPage: messages.careersPage,
  };
}
