import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        businessAccountPage(locale: $locale) {
          headSeoBreadcrumbTitle
          headSeo {
            title
            description
            image {
              url
            }
          }
          heroTitle
          heroSubtitle
          heroQuickNavId
          heroQuickNavTitle
          heroFormLinkTitle
          heroFormTitle
          heroFormSubmitLabel
          heroTitleV2
          cardsArea {
            ... on BusinessAccountPageCardSimpleRecord {
              id
              title
              description
              ctaText
              ctaLink
              componentName
            }
            ... on BusinessAccountPageCardAdvantageRecord {
              id
              title
              advantage {
                id
                title
              }
              componentName
            }
            ... on BusinessAccountPageCardBannerRecord {
              id
              componentName
            }
          }
          cardsAreaV2 {
            ... on BusinessAccountPageCardSimpleRecord {
              id
              title
              description
              ctaText
              ctaLink
              componentName
              quickNavId
              quickNavText
              imagePath
              imageAlt
              secondCtaText
              secondCtaLink
            }
            ... on BusinessAccountPageFaqRecord {
              id
              title
              componentName
              quickNavId
              quickNavText
              faqQuestions {
                title
                content
                slug
              }
            }
            ... on BusinessAccountPageFeatureRecord {
              id
              title
              titleTag
              componentName
              quickNavId
              quickNavText
              videoPath
              subtitle
              ctaText
              ctaLink
            }
            ... on BusinessAccountPageBenefitRecord {
              id
              title
              componentName
              quickNavId
              quickNavText
              benefits {
                icon
                title
              }
            }
            ... on BusinessAccountPageEligibilityRecord {
              id
              title
              componentName
              quickNavId
              quickNavText
              waitlistLabel
              sections {
                title
                content
                hasWaitlist
              }
            }
            ... on BusinessAccountProductRecord {
              id
              title
              componentName
              quickNavId
              quickNavText
              imageAlt
              imagePath
              products {
                title
                content
                chipText
              }
            }
            ... on BusinessAccountPageBlogRecord {
              id
              title
              componentName
              quickNavId
              quickNavText
              imagePath
              imageAlt
              posts {
                ctaLink
                ctaText
              }
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    businessAccountPage: messages.businessAccountPage,
  };
}
