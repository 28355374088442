import React, {
  createContext, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';

import {
  getAnnouncementVisibilityStatus,
  persistAnnouncementVisibilityStatus,
} from './services/localStorage';

export const AnnouncementContext = createContext(null);

export const AnnouncementContextConsumer = AnnouncementContext.Consumer;

export const AnnouncementContextProvider = ({
  announcementBarId,
  announcementBarDelay,
  isAllowed,
  children,
  trackClickFn,
  trackClosedFn,
  trackOpenedFn,
}) => {
  const [visibilityStatus, setVisibilityStatus] = useState(null);

  const handleClose = () => {
    const isHidden = !visibilityStatus;
    if (!announcementBarId || isHidden) return;

    setVisibilityStatus(false);
    persistAnnouncementVisibilityStatus(announcementBarId, false);
    trackClosedFn();
  };

  const setVisibleWhenAllowed = visibility => {
    const status = getAnnouncementVisibilityStatus(announcementBarId);
    if (isAllowed && (status === null || status)) {
      persistAnnouncementVisibilityStatus(announcementBarId, visibility);
      setVisibilityStatus(visibility);
      if (status === null) trackOpenedFn();
    }
  };

  useEffect(() => {
    if (!announcementBarId || !isAllowed) return () => {};
    const timeOut = setTimeout(() => {
      setVisibleWhenAllowed(true);
    }, announcementBarDelay);
    return () => clearTimeout(timeOut);
  }, [announcementBarId, isAllowed]);

  return (
    <AnnouncementContext.Provider value={{
      id: announcementBarId,
      isVisible: !!visibilityStatus,
      isAllowed,
      handleClose,
      handleClick: trackClickFn,
      setVisibleWhenAllowed,
    }}
    >
      {children}
    </AnnouncementContext.Provider>
  );
};

AnnouncementContextProvider.propTypes = {
  announcementBarDelay: PropTypes.number,
  announcementBarId: PropTypes.string,
  children: PropTypes.node.isRequired,
  isAllowed: PropTypes.bool,
  trackClickFn: PropTypes.func,
  trackClosedFn: PropTypes.func,
  trackOpenedFn: PropTypes.func,
};

AnnouncementContextProvider.defaultProps = {
  announcementBarDelay: 0,
  announcementBarId: undefined,
  isAllowed: false,
  trackClickFn: () => { },
  trackClosedFn: () => { },
  trackOpenedFn: () => { },
};
