import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        homePage(locale: $locale) {
          headSeoBreadcrumbTitle
          headSeo {
            title
            description
            image {
              url
            }
          }
          homeHeroTitle
          homeHeroDescription
          homeHeroCtaText
          homeHeroCtaLink
          homeCardsArea {
            cardTitle
            description
            cardCtaText
            cardCtaLink
            cardPreTitleText
            cardPreTitleLink
            cardComponentType
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    homePage: messages.homePage,
  };
}
