import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        businessCardPage(locale: $locale) {
          headSeoBreadcrumbTitle
            headSeo {
              title
              description
              image {
                url
              }
            }
            heroTitle
            heroDescription
            cardsArea {
              ... on BusinessCardPageCardSampleRecord {
                title
                componentName
                description
                ctaLink
                ctaText
              }
              ... on BusinessCardPageFaqRecord {
                title
                componentName
                quicknavTitle
                quicknavId
                faqQuestions {
                  id
                  title
                  content
                }
              }
            }
          }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    businessCardPage: messages.businessCardPage,
  };
}
