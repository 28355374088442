export const persistAnnouncementVisibilityStatus = (announcementId, isVisible) => {
  if (typeof window === 'undefined') return;
  localStorage.setItem(announcementId, isVisible);
};

export const getAnnouncementVisibilityStatus = announcementId => {
  if (typeof window === 'undefined') return null;
  const isVisible = localStorage.getItem(announcementId);
  return isVisible && isVisible === 'true';
};

export const persistAnnouncementAnimationStatus = (announcementId, shouldAnimate) => {
  if (typeof window === 'undefined') return;
  sessionStorage.setItem(announcementId, shouldAnimate);
};

export const getAnnouncementAnimationStatus = announcementId => {
  if (typeof window === 'undefined') return null;
  const shouldAnimate = sessionStorage.getItem(announcementId);
  return shouldAnimate && shouldAnimate === 'true';
};
