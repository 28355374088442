import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        accountPage(locale: $locale) {
          headSeoBreadcrumbTitle
          headSeo {
            title
            description
            image {
              url
            }
          }
          heroTitle
          heroDescription
          heroQuicknavId
          heroQuicknavTitle
          cardsArea {
            ... on AccountCardBenefitRecord {
              title
              componentName
              quicknavTitle
              quicknavId
              benefit {
                id
                iconName
                title
                description
              }
            }
            ... on AccountCardSampleRecord {
              title
              componentName
              quicknavTitle
              quicknavId
              description
              ctaLink
              ctaText
            }
            ... on AccountPageSectionDebitRecord {
              title
              componentName
              quicknavTitle
              quicknavId
              subtitle
              firstBlockTitle
              firstBlockDescription
              secondBlockTitle
              secondBlockDescription
            }
            ... on AccountPageSectionCalculatorRecord {
              title
              description
              observation
            }
            ... on AccountPageSectionCalculatorRecord {
              title
              componentName
              quicknavTitle
              quicknavId
            }
            ... on AccountPageSectionFeeRecord {
              title
              tableTitle
              description
              componentName
              quicknavTitle
              quicknavId
            }
            ... on AccountPageSectionSecurityRecord {
              title
              componentName
              quicknavTitle
              quicknavId
              securityItems {
                id
                title
                description
                iconName
              }
            }
            ... on AccountPageSectionFaqRecord {
              title
              componentName
              quicknavTitle
              quicknavId
              faqQuestion {
                id
                title
                content
              }
              ctaLink
              ctaText
            }
            ... on AccountPageSectionCrossSellRecord {
              title
              componentName
              cardTitle
              crossSellItem {
                id
                link
                title
              }
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    accountPage: messages.accountPage,
  };
}
