import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        faqPage(locale: $locale) {
          headSeoBreadcrumbTitle
          headSeo {
            title
            description
            image {
              url
            }
          }

          pageTitle
          faqSection {
            faqCategoryTitle
            faqCategory {
              slug
            }
          }
        }
        allFaqQuestions(locale: $locale, first: 100) {
          category {
            slug
            title
          }
          slug
          title
          content
        }
      }
    `,
    variables: {
      locale,
    },
  });

  messages.faqPage.faqSection = messages.faqPage.faqSection.map(faqSectionCategory => ({
    ...faqSectionCategory,
    faqCategory: {
      ...faqSectionCategory.faqCategory,
      faqQuestions: messages.allFaqQuestions
        .filter(faqQuestion => {
          if (!faqQuestion.category || !faqSectionCategory.faqCategory) {
            return false;
          }
          return faqQuestion.category.slug === faqSectionCategory.faqCategory.slug;
        }),
    },
  }));

  return {
    faqPage: messages.faqPage,
  };
}
