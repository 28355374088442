import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        cardPage(locale: $locale) {
          headSeoBreadcrumbTitle
          headSeo {
            title
            description
            image {
              url
            }
          }
          cardHeroTitle
          cardHeroCtaDescription
          cardCardsArea {
            ... on CardSampleCardRecord {
              id
              cardSampleCardTitle
              cardSampleCardDescription
              cardSampleCardCtaLink
              cardSampleCardCtaText
              componentName
            }
            ... on CardCarouselRecord {
              id
              carousel {
                title
                description
                imagePath
                imageAlt
              }
              componentName
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    cardPage: messages.cardPage,
  };
}
