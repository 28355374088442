import { encrypt } from '@nubank/www-latam-commons/utils/encrypt/encrypt';

export const PRODUCT_CHOICE_EXPERIMENT = {
  id: 'mpcYAapYQzaezY8IVVQiUQ',
  name: 'Product Choice',
  variants: ['1', '2'],
  variantA: '1',
  variantB: '2',
};

export const SEARCH_MVP = {
  id: 'DeJoywMWSNGPIExRYXJqaQ',
  name: 'Search MVP',
  variants: ['1'],
  variantA: '1',
};

export const CC_HIGHLIGHT = {
  id: 'Iz-sDiEwSqeKxsH1FuuljA',
  name: 'CC Highlight',
  variants: ['1'],
  variantA: '1',
  formTitle: {
    control: undefined,
    variant: 'EXPERIMENTS_CC_HIGHLIGHT_FORMTITLE_VARIANT',
  },
};

export const PHONE_REMINDER = {
  id: 'wjZHV6lCQIG36lYIjZBcMA',
  name: 'Phone Reminder',
  variants: ['1'],
  variantA: '1',
  getPhoneParametersToETL: () => {
    const value = window.sessionStorage.getItem('phone');
    if (value) {
      return {
        growth_campaign_id_smoke: encrypt(value.substr(0, 5)),
        growth_campaign_id_sea: encrypt(value.substr(5, value.length - 1)),
      };
    }
    return {};
  },
};
