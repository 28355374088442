import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        pixPage(locale: $locale) {
          headSeoBreadcrumbTitle
          headSeo {
            title
            description
            image {
              url
            }
          }
          pixHeroTitle
          pixHeroCtaDescription
          pixHeroCtaText
          pixHeroCtaLink
          pixCardsArea {
            ... on PixCardSampleRecord {
              id
              title
              description
              ctaText
              ctaLink
              componentName
            }
            ... on FaqCategoryItemRecord {
              id
              faqCategoryTitle
              faqCategory {
                slug
                faqQuestions {
                  id
                  title
                  content
                }
              }
              componentName
            }
            ... on PixOverviewRecord {
              id
              title
              description
              overview {
                id
                title
                description
              }
              componentName
            }
            ... on PixCardWhatArePixKeyRecord {
              id
              title
              description
              observation
              pixKeysTitle
              pixKeys {
                id
                title
                iconName
              }
              ctaLink
              ctaText
              componentName
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    pixPage: messages.pixPage,
  };
}
