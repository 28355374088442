import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        lifeInsurancePage(locale: $locale) {
          cardsArea {
            ... on GeneralFaqSectionRecord {
              title
              faqItems {
                title
                slug
                content
              }
              ctaLink
              ctaText
              componentName
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    lifeInsurancePage: messages.lifeInsurancePage,
  };
}
