import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
    query($locale: SiteLocale) {
      aboutUsPage(locale: $locale) {
      heroSection {
        componentName
        theNuHeaderTitle
        theNuHeaderSuperTitle
      }
      headSeo {
        title
        description
        twitterCard
      }
      headSeoBreadcrumbTitle
      aboutUsContent {
        ... on AboutUsPageCareersSectionRecord {
          id
          title
          description
          ctaLink
          ctaText
          componentName
        }
        ... on AboutUsPageDifferentSectionRecord {
          id
          title
          description
          componentName
        }
        ... on AboutUsPageInconformadosSectionRecord {
          id
          title
          description
          componentName
        }
        ... on AboutUsPageImageSectionRecord {
          id
          componentName
        }
      }
  }
    }
    `,
    variables: {
      locale,
    },
  });

  return {
    aboutUsPage: messages.aboutUsPage,
  };
}
