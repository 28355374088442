import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        pressPage(locale: $locale) {
          headSeo {
            description
            title
          }
          headSeoBreadcrumbTitle
          heroSection {
            theNuHeaderSuperTitle
            theNuHeaderTitle
          }
          pressPageCardsArea {
            ... on PressPagePressSectionRecord {
              id
              componentName
              pressCardArea {
                id
                headline
                ctaText
                ctaLink
              }
            }
            ... on PressPageOurTeamSectionRecord {
              id
              componentName
              title
              sectionOurTeamCard {
                id
                title
                description
              }
            }
            ... on PressPageTimelineSectionRecord {
              id
              componentName
              title
              sectionTimelineYearCard {
                position
                id
                position
                year
                description
              }
            }
            ... on PressPageStatisticsSectionRecord {
              id
              componentName
              pressPageStatisticsText {
                id
                pressPageStatisticsSingleText
              }
            }
            ...on PressPagePressRoomSectionRecord {
              id
              pressRoomSection {
                title
                description
                id
              }
              componentName
            }
            ... on PressPageBrandGuidelineSectionRecord {
              id
              componentName
              title
              description
              brandGuidelineLink {
                title
                link
                imageAlt
              }
            }
            ...on PressPageContactSectionRecord {
              id
              componentName
              title
              description
            }
          }
        }
      }
  `,
    variables: {
      locale,
    },
  });

  return {
    pressPage: messages.pressPage,
  };
}
