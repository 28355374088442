import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        investmentsPage (locale: $locale) {
          heroSection {
            heroSectionTitle
            heroSectionSubtitle2
            heroSectionSubtitle1
            heroSectionFootnote
            ctaBoxTitle
            ctaLink1
            ctaLink2
          }
          cardsArea {
            ... on AnbimaSectionRecord {
              id
              componentName
              anbimaSectionTitle
            }
            ... on SecureInvestmentSectionRecord {
              id
              componentName
              altText
              secureInvestmentsSectionSubtitle
              secureInvestmentsSectionTitle
            }
            ... on InvestmentOptionsSectionRecord {
              id
              componentName
              investmentIsForYouSectionAltImage
              investmentOptionsSectionTitle
              investmentOptionsSectionSubtitle
              investmentIsForYouSectionSlides {
                id
                title
                description
                ctaText
                ctaLink
                }
            }
            ... on NuAppInvestmentSectionRecord {
              id
              componentName
              nuAppInvestmentSectionTitle
              nuAppInvestmentTabs {
                id
                title
                description
                label
              }
            }
            ... on NuinvestAppInvestmentSectionRecord {
              id
              componentName
              nuinvestAppInvestmentSectionTabDescription4
              nuinvestAppInvestmentSectionTabDescription1
              nuinvestAppInvestmentSectionTabDescription2
              nuinvestAppInvestmentSectionTabDescription3
              nuinvestAppInvestmentSectionTabTitle1
              nuinvestAppInvestmentSectionTabTitle2
              nuinvestAppInvestmentSectionTabTitle3
              nuinvestAppInvestmentSectionTabTitle4
              nuinvestAppInvestmentSectionTitle
              nuinvestAppInvestmentSectionCtaLink
              nuinvestAppInvestmentSectionCtaText

            }
            ... on InvestmentIsForYouSectionRecord {
              id
              componentName
              investmentIsForYouSectionTitle
              investmentIsForYouSectionText1
              investmentIsForYouSectionText2
              investmentIsForYouSectionText3
              investmentIsForYouSectionSlides {
                title
                id
              }
            }
            ... on FaqInvestmentSectionRecord {
              id
              componentName
              faqInvestmentSectionTitle
              blogLink
              investmentsDistributionLink
              faqItems {
                id
                slug
                title
                content
              }
            }
            ... on ZeroRateSectionRecord {
              id
              componentName
              zeroRateSectionAltImage
              zeroRateSectionTitle
              zeroRateSectionTexts {
                id
                text
                link
                linkText
              }
            }
            ... on LearnToInvestSectionRecord {
              id
              componentName
              learnToInvestSectionTitle
              learnToInvestSectionLink
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    investmentsPage: messages.investmentsPage,
  };
}
