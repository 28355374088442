import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        requestNuPage(locale: $locale) {
          headSeoBreadcrumbTitle
          headSeo {
            title
            description
            image {
              url
            }
          }
          heroTitle
          shortFormTitle
          cardsArea {
            ... on RequestNuCardSampleRecord {
              id
              title
              description
              componentName
            }
            ... on RequestNuCardProductRecord {
              id
              firstProductTitle
              firstProductDescription
              secondProductTitle
              secondProductDescription
              componentName
            }
            ... on RequestNuOnNewsRecord {
              id
              title
              componentName
              news {
                id
                title
                ctaLink
                ctaText
                imagePath
                imageAlt
              }
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    requestNuPage: messages.requestNuPage,
  };
}
