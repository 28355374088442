/* eslint-disable no-unused-vars */
import * as pages from './pages';

// eslint-disable-next-line max-len
// Each KEY comes from routes.json and each VALUE is relative to the getContent.js file, imported inside ./pages as well
export const cmsPageKeyByRouteKey = {
  FAQ: 'faqPage',
  HOME: 'homePage',
  PIX: 'pixPage',
  MULTI_PRODUCT_REQUEST: 'requestNuPage',
  CARD: 'cardPage',
  ACCOUNT: 'accountPage',
  BUSINESS_ACCOUNT: 'businessAccountPage',
  BUSINESS_CARD: 'businessCardPage',
  PRESS: 'pressPage',
  CAREERS: 'careersPage',
  ABOUT_US: 'aboutUsPage',
  INVESTMENTS: 'investmentsPage',
};

export async function cmsService({ locale, preview, cache }) {
  const pagesKeys = Object.entries(pages);
  const contentsPromise = await Promise.allSettled(pagesKeys
    .map(async ([_, pageGetContent]) => (
      pageGetContent({ locale, preview, cache })
    )));

  const contents = pagesKeys.reduce((acc, _, index) => ({
    ...acc,
    ...contentsPromise[index].value,
  }), {});

  return {
    cms: {
      ...contents,
    },
  };
}
